<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-lg-6">
          <div class="card overflow-hidden">
              <div class="card-header" style="background: #3DB043 !important">
                  <div class="avatar-md profile-user-wid">
                      <span class="avatar-title rounded-circle bg-light">
                          <img class="mx-auto d-block" src="@/assets/images/login/logo.png" height="70" />
                      </span>
                  </div>
              </div>
              <div class="card-body">
                  <b-overlay :show="load">
                    <div class="mt-2 mb-2 font-weight-bold" style="font-size: 0.9rem;">
                      {{ $i18n.locale === 'en' ? 'If you have already signed up' : 'যদি পূর্বে নিবন্ধন করে থাকেন তাহলে' }}
                      <router-link to="/auth/login">
                      {{ $i18n.locale === 'en' ? 'login,' : 'লগইন' }}
                      </router-link>
                      {{ $i18n.locale === 'en' ? 'otherwise sign up by filling out the signup form below.' : 'করুন, অন্যথায় নিচের নিবন্ধন ফর্ম পূরণ করে নিবন্ধন করুন।' }}
                    </div>
                    <div class="mt-2 mb-2 font-weight-bold" style="font-size: 0.9rem;">
                      {{ $i18n.locale === 'en' ? 'Note: Mobile no will be used for username in login and One ID applies to all services.' : 'বিঃদ্রঃ লগইনে ব্যবহারকারীর নামের জন্য মোবাইল নম্বর ব্যবহার করা হবে এবং একটি আইডি সকল সেবার জন্য প্রযোজ্য।' }}
                    </div>
                    <div class="p-2">
                      <b-alert :show="errorAlert" variant=" " dismissible fade class="text-white bg-danger">
                        <div class="iq-alert-icon">
                          <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">{{ errorMsg }}</div>
                      </b-alert>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(otpSend)" @reset.prevent="reset" autocomplete="off">
                            <b-row>
                              <b-col sm="12" md="6">
                                <ValidationProvider name="Name" vid="name" rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                   <span style="font-weight:600; color: black;">{{$t('user.name')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-user fa-sm"></i></span>
                                    </b-input-group-prepend>
                                    <b-form-input
                                      id="name"
                                      v-model="user.name"
                                       :placeholder="$t('globalTrans.name')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12" md="6">
                                <ValidationProvider name="Name (bn)" vid="name_bn" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="name_bn"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                   <span style="font-weight:600; color: black;">{{$t('user.name_bn')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-user fa-sm"></i></span>
                                    </b-input-group-prepend>
                                  <b-form-input
                                    id="name_bn"
                                    v-model="user.name_bn"
                                     :placeholder="$t('globalUserData.name_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                             <b-row>
                              <b-col sm="12" md="6">
                                <ValidationProvider name="Mobile" vid="mobile_no" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="mobile_no"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  <span style="font-weight:600; color: black;">{{$t('globalUserData.mobile_no')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                    <span class="input-group-text"><i class="fa fa-phone fa-sm"></i></span>
                                  </b-input-group-prepend>
                                  <b-form-input
                                    type="number"
                                    id="mobile_no"
                                    @input="acceptNumber"
                                    v-model="user.mobile_no"
                                    :placeholder="$t('globalUserData.mobile_no')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12" md="6">
                                <ValidationProvider name="Email" vid="email" rules="email">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  <span style="font-weight:600; color: black;">{{$t('globalTrans.email')}}</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-envelope fa-sm"></i></span>
                                    </b-input-group-prepend>
                                    <b-form-input
                                      id="email"
                                      v-model="user.email"
                                      :placeholder="$t('globalTrans.email')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col sm="12" md="6">
                               <ValidationProvider name="Password" vid="password" rules="required|min:6">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="password"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  <span style="font-weight:600; color: black;">{{$t('globalTrans.password')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-lock fa-sm"></i></span>
                                    </b-input-group-prepend>
                                    <b-form-input
                                      id="password"
                                      type="password"
                                      v-model="user.password"
                                      :placeholder="$t('globalTrans.password')"
                                      autocomplete="new-password"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12" md="6">
                                 <ValidationProvider name="Confirm password" vid="password_confirmation" rules="required|confirmed:password">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="password_confirmation"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  <span style="font-weight:600; color: black;">{{$t('globalTrans.confirm_password')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-lock fa-sm"></i></span>
                                    </b-input-group-prepend>
                                    <b-form-input
                                      id="password_confirmation"
                                      type="password"
                                      v-model="user.password_confirmation"
                                      autocomplete="new-password"
                                      :placeholder="$t('globalTrans.confirm_password')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <div class="row mt-3">
                              <div class="col-md-6 offset-md-3 text-center">
                                <button class="btn btn-success btn-block" type="submit">{{ $t('globalTrans.sign_up') }}</button>
                              </div>
                            </div>
                            <div class="mt-1 text-center">
                                <router-link to="/auth/login">{{ $t('globalTrans.back_to_login') }}</router-link>
                            </div>
                        </b-form>
                      </ValidationObserver>
                    </div>
                  </b-overlay>
              </div>
          </div>
      </div>
    </div>
    <b-modal ref="mymodal" id="modal-4" size="md" :title="$t('globalTrans.otp')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Otp :user="user" :timer="timer"></Otp>
    </b-modal>
  </div>
</template>

<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { signUp, sendOtp } from '../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Otp from './Otp'
const FIVE_MINITES = 60 * 5

export default {
  components: { ValidationObserver, ValidationProvider, Otp },
  data: () => ({
    loading: false,
    defaultValue: '',
    user: {
      name: '',
      name_bn: '',
      email: '',
      mobile_no: '',
      username: '',
      password: '',
      password_confirmation: '',
      otp_code: '',
      user_type_id: 99
    },
    FIVE_MINITES: FIVE_MINITES,
    errorAlert: false,
    load: false,
    timer: 0,
    errorMsg: ''
  }),
  mounted () {
  },
  computed: {
    userTypeList () {
      return this.$store.state.Auth.userTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.user_type_name_bn }
        } else {
          return { value: item.value, text: item.user_type_name }
        }
      })
    }
  },
  methods: {
    async otpSend () {
      this.load = true
      let result = null
      result = await RestApi.postData(authServiceBaseUrl, sendOtp, this.user)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.a_otp_send_your_mobile'),
          color: '#D6E09B'
        })
        this.$refs.mymodal.show()
        this.timer = this.FIVE_MINITES
        this.load = false
      } else {
        this.load = false
        this.$refs.form.setErrors(result.errors)
      }
    },
    async signup () {
      try {
        this.loading = true
        const result = await RestApi.postData(authServiceBaseUrl, signUp, this.user)
        if (result.success) {
          this.$router.push('otp/' + result.user_id)
        } else {
          this.errorAlert = true
          this.errorMsg = 'Failed to sign up. Please, try again.'
          this.$refs.form.setErrors(result.errors)
        }
      } catch (error) {
        if (error) {
          //
        }
        this.errorAlert = true
        this.errorMsg = 'Failed to sign up. Please, try again.'
      }

      this.loading = false
    },
    acceptNumber () {
    var x = this.user.mobile_no.replace(/\D/g, '').match(/^(01[356789])(\d{8})$/)
        if (!x) {
            this.user.mobile_no = ''
        }
    }
  }
}
</script>

<style scoped>
        .label{font-weight: 600 !important;}
        .card{border: 0px; opacity: 0.8 !important}
        .card-header{background-color: #c7cef8 !important; border-bottom: 0px!important;}
        .btn-custom{background-color: #596DE1; color: #fff;}
        .form-control{height:30px !important; background-color:var(--iq-light) !important;
        }
        .form-group{margin-bottom:.1rem !important;}
        select{font-size: 12px !important;}
        .card-header{padding: 0.35rem 1.25rem !important;}
        .card-body {padding: 0.10rem 1.25rem !important;}
        .input-group-text {
        border: 1px solid #515252 !important;
        }

</style>
